<template>
	<div>
		<a-upload name="file" class="up-img" list-type="picture-card" :show-upload-list="false" :action="api"
			:before-upload="beforeUpload" @change="handleChange">
			<div v-if="img" class="imgs">
				<img v-if="img" :src="img + '!120.120'" alt="avatar" />
				<p @click.stop="show_img(img)">查看</p>
			</div>
			<!-- <div v-if="fileUrl" class="imgs">
				<img v-if="fileUrl" :src="fileUrl" alt="avatar" />
				<p @click.stop="show_img(fileUrl)">查看</p>
			</div> -->
			<a-icon v-if="!img" type="plus" />
		</a-upload>
	</div>
</template>

<script>
export default {
	data() {
		return {
			api: process.env.VUE_APP_IMG_API,
			img: ''
		}
	},
	props: {
		fileUrl: {
			type: String
		}
	},
	methods: {
		init(e) {
			this.img = e
		},
		handleChange(info) {
			if (info.file.status === 'uploading') {
				return;
			}
			if (info.file.status === 'done') {
				this.img = info.file.response.data
				this.$emit("ok", this.img)
				this.img = ''
			}
		},
		beforeUpload(file) {
			const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			if (!isJpgOrPng) {
				this.$message.error('必须上传图片格式!');
				return
			}
			const isLt1M = file.size / 1024 / 1024 < 5;
			if (!isLt1M) {
				this.$message.error('上传图片不能大于5M!');
			}
			return isLt1M;
		},
		show_img(e) {
			const imgWindow = window.open('');
			imgWindow && imgWindow.document.write("<image src='" + e + "' style='display: flex; margin: 0 auto'/>");
		}
	}
}
</script>

<style>
.up-img img {
	width: 98px;
}
</style>
